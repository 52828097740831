.Card {
    max-width: 95%;
    min-width: 52%;
    user-select: none;
}

.Sent {
    align-self: flex-end;
    color: #ED4192;
}

.Received {
    align-self: flex-start;
}