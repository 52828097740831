.Space {
    margin-top: 10px;
    margin-bottom: 130px;
    padding: 0 20px;
    max-width: 600px;
    width: 100%;
    flex-direction: column;
    column-gap: 8px;
    row-gap: 16px;
    display: inline-flex;
    text-align: center;
    height: 100vh;
    overflow-y: scroll;
}

.Form {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 600px;
    width: 100%;
    padding: 0 20px;
}

.TextArea {
    margin-bottom: -1px;
}

.SendButton {
    color: #ed4192;
}